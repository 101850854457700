import './polyfills';
import 'src/styles/main.scss';

// eslint-disable-next-line no-restricted-imports
import { useAnalytics } from '@happykit/analytics';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import type { FunctionComponent } from 'react';
import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import {
  getClientCookie,
  setClientCookie,
} from 'src/utils/client/ClientCookieUtils';
import { isClientSide } from 'src/utils/EnvUtils';

import { FlagsProvider } from 'flags/client';

const App: FunctionComponent<AppProps> = ({
  Component,
  pageProps,
  // @ts-expect-error (internal prop)
  err,
}) => {
  useAnalytics({ publicKey: 'analytics_pub_4331d53f06' });

  useEffect(() => {
    if (isClientSide() && !getClientCookie('Idempotency-Key')) {
      setClientCookie('Idempotency-Key', uuid(), 60 * 60 * 24 * 365);
    }
  }, []);

  return (
    <div className="content">
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
        />
        <meta
          name="robots"
          content={
            process.env.DEPLOYMENT_ENV === 'production'
              ? 'index, follow'
              : 'noindex, nofollow'
          }
        />
      </Head>

      <FlagsProvider>
        <Component {...pageProps} _err={err} />
      </FlagsProvider>

      {process.env.NODE_ENV === 'production' && (
        <>
          {/* OneTrust */}
          <Script
            type="text/javascript"
            src={`https://cdn.cookielaw.org/consent/${process.env.NEXT_PUBLIC_ONE_TRUST_ID}/OtAutoBlock.js`}
          />
          <Script
            type="text/javascript"
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            charSet="UTF-8"
            data-domain-script={`${process.env.NEXT_PUBLIC_ONE_TRUST_ID}`}
          />
          <Script
            id="one-trust"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `function OptanonWrapper() {
                      document.querySelector(".ot-pc-footer-logo").style.display = "none";
                    }`,
            }}
          />
          {/* End OneTrust */}
          {/* Google Tag Manager */}
          <Script
            id="gtm"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://sgtm.ad.fr/y9sayaji3rc76mq.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`,
            }}
          />
          {/* End Google Tag Manager */}
          {/* Google Tag Manager (noscript) */}
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://sgtm.ad.fr/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}
          />
          {/* End Google Tag Manager (noscript) */}
        </>
      )}
    </div>
  );
};

export default App;
